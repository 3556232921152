@import url(https://fonts.googleapis.com/css?family=Exo:400,700);
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

body {
  background-color: #555;
}

.App {
  text-align: center;
  position: relative;
  z-index: 0;
}

.App-logo {
  height: 4rem;
  background: hsla(0, 0%, 100%, .5);
}

.App-logo::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-filter: blur(20px);
          filter: blur(20px);
  z-index: -1;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.panel {
  background-color: rgba(200, 200, 200, 0.9);
  /* backdrop-filter: blur(10px); */
}

.pdf-container {
  position: fixed;
  width: calc(100vw - 1rem);
  height: calc(100vh - 3.5rem);
  z-index: 25;
  margin: .5rem;
  top: 0;
  left: 0;
  box-shadow: 5px 5px 20px #333333;
}

.pdf-close {
  position: fixed;
  z-index: 30;
  top: 2px;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
}

 th {
  background-color: rgba(200, 200, 200, 0.9);
} 

.search {
  z-index: 5;
  position: -webkit-sticky;
  position: sticky;
  top: 5px;
  /* backdrop-filter: blur(10px); */
}

.xyz {
  background: rgba(200, 200, 200, 0.9);
  border: none;
}

input[type=text]:focus {
  background: rgba(255, 255, 255, 0.9);
}

.form-control::-webkit-input-placeholder {
  color: #292b2c;
}

.form-control:-ms-input-placeholder {
  color: #292b2c;
}

.form-control::placeholder {
  color: #292b2c;
}

.preview { 
  /* display: none; */
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-45%, -50%);
          transform: translate(-45%, -50%);
  max-width: 90vw;
  max-height: 90vh;
  box-shadow: 5px 5px 20px #333333;
  z-index: 45;
}

.table thead > tr > th {
  border: none;
}

tbody > tr:hover {
  background-color: hsla(0, 0%, 100%, .5);
}
/* 
.table tbody > tr:first-child > td {
  border: none
} */

.table tbody > tr:last-child > td:first-child {
  border-radius: 0 0 0px 5px;
}

.table tbody > tr:last-child > td:last-child {
  border-radius: 0 0 5px 0px;
}

.table tbody > tr > td {
  background-color: rgba(50, 50, 50, .9);
  text-shadow: 2px 2px 8px black;
  vertical-align: middle;
} 

table > tbody > tr:last-child > td {
  border: none !important;
}

th {
  cursor: pointer;
}

.testa {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 100px !important;
}

th:hover {
  background-color: rgba(150, 150, 150, 0.9);
}

thead > tr > th:nth-child(1):hover,
thead > tr > th:nth-child(2):hover,
thead > tr > th:nth-child(3):hover,
thead > tr > th:last-child:hover {
  background-color: transparent;
  background-color: initial;
}

.info-icona {
  color: #5bc0de;
}

.info-icona:hover {
  color: darkslategray;
}

/* .pdf-icon {
  color: green !important;
} */

.pdf-icon:hover {
  color: #f8f9fa !important
}

table {
  /* backdrop-filter: blur(10px); */
  /* -webkit-backdrop-filter: blur(5px); */
}

.text-teal {
  color: cyan
}

.text-yellow {
  color: yellow
}

body{
    font-family: 'Exo', sans-serif;
}

.info {
  display: none;
  position: fixed;
  left: 5px;
  top: 5px;
  width: calc(100vw - 10px);
  height: calc(100vh - 10px);
  z-index: 100;
  /* backdrop-filter: blur(5px); */
  background-color: rgba(75, 75, 75, 0.9);
}

.asm-icon {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.asm-sub-icon {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-size: .5em;
}
.batngrup {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
}

.batngrup button {
  margin: .25em;
}

.ti {
  max-width: 20rem;
}

.info-data {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
}

@media screen and (max-width: 600px) {
  .info-data {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

.flexo {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: left;
          justify-content: left;
}

.underl {
  color: lightblue;
  font-weight: 300;
}

.asmtip {
  width:100%;
  height: 100%;
  background-color: red;
}

.root-asm {
  background-color: rgba(75, 50, 50, 0.5); 
}

.legenda {
  position: absolute;
  top: .5em;
  left: .5em;
  border: 1px solid grey;
  border-radius: .5em;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  justify-items: center;
  padding: .5em;
  background: hsla(0, 0%, 100%, .5);
}

.btn-custom {
  width: 2.5rem;
  height: 2.5rem;
}

.btn-custom:hover {
  background-color: hsla(0, 0%, 100%, .5);
}

.btn-custom:active {
  background-color: hsla(0, 0%, 50%, .5);
}

.preview-image {
  max-width: calc(100vw - 12rem);
  max-height: calc(100vh - 1rem);
}

.table-image {
  max-width: 64px;
  max-height: 96px;
}

ul {
  list-style-type: none;
}

.link {
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

.loading {
  position: fixed;
  font-size: 20rem;
  z-index: 9999;
  background-color: hsla(0, 0%, 100%, .5);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

.text-cyan {
  color: yellow
}

.filter-text:hover {
  color: black !important;
}

/* width */
::-webkit-scrollbar {
  width: .5rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ccc;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #222;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #111;
}

.detail-view {
  z-index: 10;
  height: calc(100vh - 1rem);
  width: calc(100vw - 1rem);
  top: 0;
  left: 0;
  margin: .5rem;
  background-color: rgba(50, 50, 50, .95);
  border-radius: .3rem;
}

.relate-image {
  max-width: 23rem;
}

.detail-list li {
  margin-top: .3rem;
}

.cursor-pointer {
  cursor: pointer;
}

.no-text-shadow {
  text-shadow: none;
}

.pdf-thumbnail {
  height: 90vh;
  width: 80vw;
  max-width: 80vw;
}
